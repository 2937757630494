.App {
  align-items: center;
  min-height: 100vh;
}

.SearchInput {
  margin-top: 20px;
  background-color: transparent;
  border: 0;
  color: black;
  width: 100%;
  text-align: center;
  caret-color: transparent;
  font-family: "JetBrains Mono";

  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}

.PostContainer {
  margin-top: 40px;
  align-items: center;
  text-align: center;
}
.PostItem {
  margin-bottom: 32px;
}
.PostTitle {
  text-decoration: none;
  color: black;
  display: block;
  font-family: "JetBrains Mono";
}
.PostLink {
  text-decoration: none;
  color: grey;
  font-size: 12px;
}
*:focus {
  outline: none;
}
